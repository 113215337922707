
import { Label, Stack } from '@fluentui/react';
import React from 'react';
import * as MessageScreenStyle from "../styles/MessageScreenStyle"

interface MessageScreenProps {
    text: string,
    actionButton?: JSX.Element;
};

const MessageScreen: React.FC<MessageScreenProps> = ({
    text,
    actionButton
}: MessageScreenProps) => {
    return (
        <Stack horizontalAlign="center" verticalAlign="space-around" className="xb-loader-container" style={MessageScreenStyle.messageContainer}>
            <Stack.Item>
                <Label styles={MessageScreenStyle.titleStyle}>
                    Docubird Admin Portal
                </Label>
            </Stack.Item>
            <Stack.Item>
                <Label styles={MessageScreenStyle.messageLabel}>
                    {text}
                </Label>
            </Stack.Item>
            <Stack.Item>
                {actionButton}
            </Stack.Item>
        </Stack>
    )
}

export default MessageScreen;