import axios from "axios"
import { AssignSubscriptionRequest, CreateSubscriptionRequest, DateRange, ExactWebHookSubscriptionRequest, IAddTenantRequest, IAdminStatusUpdate, IBoundField, IBulkUpdate, IDatasource, IDatasourceAuthDto, IDatasourceRequestDto, IDatasourceType, IDocumentLibrary, IPartnerProfile, IPortalPartner, IPortalTenantRequestFeedback, IPortalUser, IRequest, ISite, ITenant, ITenantUser, IUserProfileRequest, MultiTenantSubscriptionRequest, PaymentStatusResponse, SubscriptionPriceTierResponse, SubscriptionStatusUpdateRequest, TenantApiKeyUpdateRequest, UserDeleteRequest } from "../../data-structures/interfaces";
import { Client } from "@microsoft/microsoft-graph-client/lib/src/browser";
import { ErrorHelper } from "./ErrorHelper";
import { apiHeaders, axiosDelete, axiosGet, axiosPost, axiosPut } from "./AxiosUtil";
import { AuthHelper, IJwtAuthTokenResult } from "./AuthHelper";
import { SubscriptionIntervalEnum, SubscriptionTierEnum } from "../../data-structures/enums";

export const setAuthToken = async (profileRequest: IUserProfileRequest) => {
    try {
        const authToken: IJwtAuthTokenResult = (await axios.post(`${process.env.REACT_APP_API_URL}Auth/GetAuthToken`, profileRequest, {
            headers: {
                [apiHeaders.key.xDocubirdAuth]: apiHeaders.value.xDocubirdAuth
            }
        }))?.data;

        if (authToken) {
            AuthHelper.setDocubirdToken(authToken);
            return true;
        }
    } catch (e) {
        ErrorHelper.handle(e);
    }
    return false;
}

export const refreshJwt = async (accessToken: string) => {
    try {
        // Use default axios instance to skip access token refresh interceptor
        const result: IJwtAuthTokenResult = (await axios.get(
            `${process.env.REACT_APP_API_URL}auth/RefreshJwt`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    [apiHeaders.key.xDocubirdAuth]: apiHeaders.value.xDocubirdAuth
                }
            }
        ))?.data;
        return result;
    } catch (e) {
        ErrorHelper.handle(e);
    }
    return null;
}

export const getCurrentProfile = async (profileRequest: IUserProfileRequest) => {
    try {
        const currentProfile: IPortalUser = (await axiosPost(`User/GetProfile`, profileRequest))?.data;
        return currentProfile;
    } catch (e) {
        ErrorHelper.handle(e);
        return null;
    }
}

export const saveUserChanges = async (user: ITenantUser) => {
    try {
        await axiosPut(`User/Update/`, user);
    } catch (e) {
        ErrorHelper.handle(e);
        return null;
    }
}

export const getPartnerProfile = async (partnerId: number) => {
    try {
        const partnerProfile: IPartnerProfile = (await axiosGet(`Partner/GetProfile/${partnerId}`))?.data;
        return partnerProfile;
    } catch (e) {
        ErrorHelper.handle(e);
        return null;
    }
}

export const updateTenant = async (tenant: ITenant) => {
    try {
        await axiosPut(`Tenant/Update`, tenant);
    } catch (e) {
        ErrorHelper.handle(e);
        return null;
    }
}

export const updateTenantApiKey = async (request: TenantApiKeyUpdateRequest) => {
    try {
        await axiosPut(`tenant/updateapikey`, request);
        return true;
    } catch (e) {
        ErrorHelper.handle(e);
        return false;
    }
}

export const removeTenant = async (partnerId: number, tenant: ITenant) => {
    try {
        await axiosPut(`Partner/RemoveTenant/${partnerId}`, { tenantId: tenant.tenantId });
    } catch (e) {
        ErrorHelper.handle(e);
        return null;
    }
}

export const bulkUpdates = async (bulkUpdates: IBulkUpdate) => {
    try {
        await axiosPut(`User/BulkUpdate/`, bulkUpdates);
    } catch (e) {
        ErrorHelper.handle(e);
        return null;
    }
}

export const updateAdminStatus = async (bulkUpdates: IAdminStatusUpdate) => {
    try {
        await axiosPut(`User/UpdateAdminStatus/`, bulkUpdates);
    } catch (e) {
        ErrorHelper.handle(e);
        return null;
    }
}

export const removeTenantsAPI = async (partnerId: number, tenantIds: number[]) => {
    try {
        await axiosPut(`Partner/RemoveTenants/${partnerId}`, tenantIds);
    } catch (e) {
        ErrorHelper.handle(e);
        return null;
    }
}

export const addTenantAPI = async (tenant: ITenant) => {

    const addTenantRequest: IAddTenantRequest = {
        ...tenant,
        allowedAdvancedSearchFields: tenant.allowedAdvancedSearchFields ? (tenant.allowedAdvancedSearchFields as string[]).map(x => x).join(";") : "",
        allowedSiteCollections: tenant.allowedSiteCollections ? (tenant.allowedSiteCollections as string[]).map(x => x).join(";") : "",
        allowedTeams: tenant.allowedTeams ? (tenant.allowedTeams as string[]).map(x => x).join(";") : "",
        hiddenMetadataFields: tenant.hiddenMetadataFields ? (tenant.hiddenMetadataFields as string[]).map(x => x).join(";") : "",
    }

    try {
        const newTenant: ITenant = (await axiosPost(`Tenant/AddTenant`, addTenantRequest))?.data;
        return {
            success: true,
            tenant: newTenant
        }
    } catch (e) {
        ErrorHelper.handle(e);
        return {
            success: false,
            message: e.response?.data
        }
    }
}

export const assignTenantsAPI = async (partnerId: number, tenantIds: number[]) => {
    try {
        await axiosPut(`Partner/AssignTenants/${partnerId}`, tenantIds);
    } catch (e) {
        ErrorHelper.handle(e);
        return null;
    }
}

export const assignPartnerUsers = async (partnerId: number, userIds: number[]) => {
    try {
        await axiosPut(`Partner/AssignUsers/${partnerId}`, userIds);
    } catch (e) {
        ErrorHelper.handle(e);
        return null;
    }
}

export const removePartnerUsers = async (partnerId: number, userIds: number[]) => {
    try {
        await axiosPut(`Partner/RemoveUsers/${partnerId}`, userIds);
    } catch (e) {
        ErrorHelper.handle(e);
        return null;
    }
}

export const getPartners = async () => {
    try {
        const partners = (await axiosGet(`Partner`))?.data as IPortalPartner[];
        return partners;
    } catch (e) {
        ErrorHelper.handle(e);
        return null;
    }
}

export const createPartner = async (partner: IPortalPartner) => {
    try {
        const newPartner = (await axiosPost(`Partner/Add`, partner))?.data as IPortalPartner;
        return newPartner;
    } catch (e) {
        ErrorHelper.handle(e);
        return null;
    }
}

export const updatePartner = async (partnerId: number, name: string) => {
    try {
        await axiosPut(`Partner/Edit`, { id: partnerId, name });
    } catch (e) {
        ErrorHelper.handle(e);
        return null;
    }
}

export const deletePartner = async (partnerId: number) => {
    try {
        await axiosDelete(`Partner/Delete/${partnerId}`,);
    } catch (e) {
        ErrorHelper.handle(e);
        return null;
    }
}

export const requestTenants = async (userId: number, partnerId: number, requestedTenants: ITenant[]) => {
    try {
        await axiosPost(`Partner/requesttenants/${userId}/${partnerId}`, requestedTenants);
    } catch (e) {
        ErrorHelper.handle(e);
        return e.response?.data?.error;
    }
}

export const loadRequests = async () => {
    try {
        const result: IRequest[] = (await axiosGet(`tenantrequest/load`))?.data;
        return result
    } catch (e) {
        ErrorHelper.handle(e);
        return null;
    }
}

export const updateRequest = async (requestFeedback: IPortalTenantRequestFeedback) => {
    try {
        await axiosPut(`tenantrequest/update`, requestFeedback);
    } catch (e) {
        ErrorHelper.handle(e);
        return null;
    }
}

export const deleteTenant = async (tenantId: number) => {
    try {
        await axiosDelete(`tenant/delete/${tenantId}`);
    } catch (e) {
        ErrorHelper.handle(e);
        return null;
    }
}

export const getTenantSites = async (tenantId: number) => {
    try {
        const sites: ISite[] = (await axiosGet(`tenant/sites/${tenantId}`))?.data;
        const mappedSites: ISite[] = sites?.map(site => {
            return ({
                ...site,
                documentLibraries: null,
            })
        })
        return mappedSites;
    } catch (e) {
        ErrorHelper.handle(e);
        return null;
    }
}

export const addTenantSites = async (tenantId: number, sites: ISite[]) => {
    try {
        //remove libs from sites
        const processedSites = [...sites].map(site => {
            delete site.documentLibraries;
            return site;
        })
        const updatedSites = (await axiosPost(`tenant/addsites/${tenantId}`, processedSites));
        return updatedSites?.data;
    } catch (e) {
        ErrorHelper.handle(e);
        return null;
    }
}

export const clearTenantUserEmailMappings = async (tenantId: string) => {
    try {
        await axiosPut(`tenant/clearuseremailmappings/${tenantId}`);
    } catch (e) {
        ErrorHelper.handle(e);
    }
}

export const deleteSite = async (siteIds: number[]) => {
    try {
        await axiosPut(`site/delete`, siteIds);
    } catch (e) {
        ErrorHelper.handle(e);
    }
}

export const getSiteLibraries = async (siteId: number) => {
    try {
        const libraries: IDocumentLibrary[] = (await axiosGet(`site/documentlibraries/${siteId}`))?.data;
        return libraries;
    } catch (e) {
        ErrorHelper.handle(e);
        return null;
    }
}

export const addSiteLibrary = async (siteId: number, libraries: IDocumentLibrary[]) => {
    try {
        const updatedLibrariesCall = (await axiosPost(`site/adddocumentlibraries/${siteId}`, libraries));
        const updatedLibraries: IDocumentLibrary[] = updatedLibrariesCall?.data;
        return updatedLibraries;
    } catch (e) {
        ErrorHelper.handle(e);
        return null;
    }
}

export const deleteLibrary = async (libIds: number[]) => {
    try {
        await axiosPut(`documentlibrary/delete`, libIds);
    } catch (e) {
        ErrorHelper.handle(e);
    }
}

export const updateLibrary = async (libraries: IDocumentLibrary[]) => {
    try {
        axiosPut(`documentlibrary/update`, libraries);
    } catch (e) {
        ErrorHelper.handle(e);
    }
}

export const updateSite = async (sites: ISite[]) => {
    try {
        const processedSites = [...sites].map(site => {
            delete site.documentLibraries;
            return site;
        })
        axiosPut(`site/update`, processedSites);
    } catch (e) {
        ErrorHelper.handle(e);
    }
}

export const getTenantDatasource = async (tenantId: number): Promise<IDatasource[]> => {
    try {
        const datasources = (await axiosGet(`tenant/${tenantId}/datasources`))?.data;
        return datasources ?? [];
    } catch (err) {
        ErrorHelper.handle(err);
        return [];
    }
}

export const getDatasourceStructure = async (requestDto: IDatasourceRequestDto): Promise<string[]> => {
    try {
        const datasourceStructure = (await axiosPost(`datasource/getStructure`, requestDto))?.data;
        return datasourceStructure;
    } catch (err) {
        ErrorHelper.handle(err);
        return null;
    }
}

export const createNewDatasource = async (datasource: IDatasource): Promise<IDatasource> => {
    try {
        const datasourceStructure = (await axiosPost(`datasource/add`, datasource))?.data;
        return datasourceStructure;
    } catch (err) {
        ErrorHelper.handle(err);
        return null;
    }
}

export const editDatasource = async (datasource: IDatasource): Promise<number> => {
    try {
        const res = (await axiosPut(`datasource/edit`, datasource));
        return res.status;
    } catch (err) {
        ErrorHelper.handle(err);
        return null;
    }
}

export const resetDatasourceCache = async (datasource: IDatasource): Promise<number> => {
    try {
        const res = (await axiosPut(`datasource/resetcache`, datasource));
        return res.status;
    } catch (err) {
        ErrorHelper.handle(err);
        return null;
    }
}

export const deleteDatasource = async (datasourceId: number) => {
    try {
        const res = await axiosDelete(`datasource/delete/${datasourceId}`);
        return res.status;
    } catch (err) {
        ErrorHelper.handle(err);
        return null;
    }
}

export const createNewBoundField = async (boundfield: IBoundField): Promise<IBoundField> => {
    try {
        const field = (await axiosPost(`datasource/boundfield/add`, boundfield))?.data;
        return field;
    } catch (err) {
        ErrorHelper.handle(err);
        return null;
    }
}

export const updateBoundFields = async (boundfields: IBoundField[]): Promise<number> => {
    try {
        const res = await axiosPut(`datasource/boundfields/update`, boundfields);
        return res.status;
    } catch (err) {
        ErrorHelper.handle(err);
        return null;
    }
}

export const deleteBoundField = async (id: number): Promise<number> => {
    try {
        const res = await axiosDelete(`datasource/boundfield/delete/${id}`);
        return res.status;
    } catch (err) {
        ErrorHelper.handle(err);
        return null;
    }
}

export const getDatasourceTypes = async (): Promise<IDatasourceType[]> => {
    try {
        const types = (await axiosGet(`datasource/types`))?.data;
        return types;
    } catch (err) {
        ErrorHelper.handle(err);
        return null;
    }
}

const getAuthenticatedClient = (accessToken: string) => {
    return Client.init({
        authProvider: (done: any) => {
            done(null, accessToken);
        }
    });
}

export const getListStructure = async (accessToken: string, siteUrl: string, listTitle: string) => {
    try {
        const siteUrlObject = new URL(siteUrl);
        const client = getAuthenticatedClient(accessToken);
        const siteResponse = await client
            .api(`/sites/${siteUrlObject.hostname}:/${siteUrlObject.pathname}`)
            .get();

        if (siteResponse?.id) {
            await client
                .api(`/sites/${siteResponse.id}/lists/${listTitle}`)
                .expand("columns")
                .get();
        }
    }
    catch (e) {
        ErrorHelper.handle(e);
    }
    return null;
}

export const createSubscription = async (request: CreateSubscriptionRequest) => {
    try {
        const field = (await axiosPost(`subscriptions/create`, request))?.data;
        return field;
    } catch (err) {
        ErrorHelper.handle(err);
        return null;
    }
}

export const listAvailablePaymentMethods = async () => {
    try {
        const data = (await axiosGet(`subscriptions/paymentmethods`))?.data;
        if (data?._embedded) {
            return data?._embedded
        } else {
            return [];
        }
    } catch (err) {
        ErrorHelper.handle(err);
        return [];
    }
}

const transformSubscriptionData = (sub, languageStrings, tenants = null) => {
    let availableLicensesText = "-";
    if (!(sub.method === "invoice" || sub.method === "invoice_dynamic" || sub.tier === SubscriptionTierEnum.Trial)) {
        availableLicensesText = `${sub.availableLicenses ? sub.availableLicenses : "0"} of ${sub.licenseCount}`;
    }

    let assignedLicensesText = sub.assignedLicenses ? sub.assignedLicenses.toString() : "-";
    let intervalText = "";

    switch (sub.interval) {
        case SubscriptionIntervalEnum.OnceOff:
            intervalText = languageStrings.OnceOff;
            break;
        case SubscriptionIntervalEnum.Monthly:
            intervalText = languageStrings.Monthly;
            break;
        case SubscriptionIntervalEnum.Yearly:
            intervalText = languageStrings.Yearly;
            break;
        default:
            break;
    }

    let expiryDateText = "-";
    if (sub.expiryDate) {
        expiryDateText = new Date(sub.expiryDate).toLocaleString();
    }

    const transformedSub = {
        ...sub,
        availableLicenses: availableLicensesText,
        interval: intervalText,
        assignedLicenses: assignedLicensesText,
        expiryDateRaw: sub.expiryDate ? new Date(sub.expiryDate) : null,
        expiryDate: expiryDateText,
        amountString: sub.amountString ? sub.amountString : "-",
    };

    if (tenants) {
        transformedSub.tenantName = tenants.find(x => x.id === sub.tenantId)?.friendlyName || "-";
    }

    return transformedSub;
};

export const getTenantSubscriptions = async (tenantId: number, languageStrings: any): Promise<any[]> => {
    try {
        const subscriptions = (await axiosGet(`subscriptions/tenant/${tenantId}`))?.data;
        return subscriptions.map(sub => transformSubscriptionData(sub, languageStrings));
    } catch (err) {
        ErrorHelper.handle(err);
        return [];
    }
}

export const getMultiTenantSubscriptions = async (request: MultiTenantSubscriptionRequest, tenants: ITenant[], languageStrings: any): Promise<any[]> => {
    try {
        const subscriptions = (await axiosPost(`subscriptions/multi`, request))?.data;
        return subscriptions.map(sub => transformSubscriptionData(sub, languageStrings, tenants));
    } catch (err) {
        ErrorHelper.handle(err);
        return [];
    }
}

export const getTenantSubscriptionsPayments = async (tenantId: number) => {
    try {
        const data = (await axiosGet(`subscriptions/payments/tenant/${tenantId}`))?.data;

        return data;
    } catch (err) {
        ErrorHelper.handle(err);
        return [];
    }
}

export const assignUsersToSubscription = async (request: AssignSubscriptionRequest) => {
    try {
        const results = (await axiosPost(`subscriptions/users/assign`, request))?.data;
        return results;
    } catch (err) {
        ErrorHelper.handle(err);
        return null;
    }
}

export const assignAllUsersToSubscription = async (request: AssignSubscriptionRequest): Promise<number[]> => {
    try {
        const results = (await axiosPost(`subscriptions/users/assignall`, request))?.data;
        return results;
    } catch (err) {
        ErrorHelper.handle(err);
        return null;
    }
}

export const revokeUsersFromSubscription = async (request: AssignSubscriptionRequest) => {
    try {
        const results = (await axiosPost(`subscriptions/users/revoke`, request))?.data;
        return results;
    } catch (err) {
        ErrorHelper.handle(err);
        return null;
    }
}

export const revokeAllUsersFromSubscription = async (subscriptionId: number) => {
    try {
        const results = (await axiosDelete(`subscriptions/users/revokeall/${subscriptionId}`))?.data;
        return results;
    } catch (err) {
        ErrorHelper.handle(err);
        return null;
    }
}

export const cancelSubscription = async (subscriptionId: number) => {
    try {
        const results = (await axiosDelete(`subscriptions/cancel/${subscriptionId}`))?.data;
        return results;
    } catch (err) {
        ErrorHelper.handle(err);
        return null;
    }
}

export const getPaymentStatus = async (transactionId: string): Promise<PaymentStatusResponse> => {
    try {
        const data = (await axiosGet(`subscriptions/paymentstatus/${transactionId}`))?.data;

        return data;
    } catch (err) {
        ErrorHelper.handle(err);
        return null;
    }
}

export const updateSubscriptionStatus = async (request: SubscriptionStatusUpdateRequest) => {
    try {
        const results = (await axiosPut(`subscriptions/updatestatus`, request))?.data;
        return results;
    } catch (err) {
        ErrorHelper.handle(err);
        return null;
    }
}

export const getSubscriptionPriceTiers = async (): Promise<SubscriptionPriceTierResponse[]> => {
    try {
        const data = (await axiosGet(`subscriptions/pricetiers`))?.data;

        return data;
    } catch (err) {
        ErrorHelper.handle(err);
        return null;
    }
}

export const getExactAuthToken = async (endpoint: string, code: string, state: string) => {
    try {
        // const data = (await axiosGet(`${endpoint}?&code=${encodeURIComponent(code.trim())}&state=${encodeURIComponent(state.trim())}`))?.data;
        // return data;
        const authToken = (await axios.get(
            `${endpoint}?&code=${encodeURIComponent(code.trim())}&state=${encodeURIComponent(state.trim())}`,
            {
                headers: {
                    [apiHeaders.key.xDocubirdAuth]: apiHeaders.value.xDocubirdAuth
                }
            }))?.data;
        return authToken;
    } catch (err) {
        ErrorHelper.handle(err);
        return null;
    }
}

export const exactCreateWebHookSubscription = async (request: ExactWebHookSubscriptionRequest) => {
    try {
        const results = (await axiosPost("Exact/HookSubscription", request))?.data;
        return results;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export const exactGetWebHookSubscriptions = async (clientId: string) => {
    try {
        const results = (await axiosGet(`Exact/WebHookSubscriptions/${clientId}`))?.data;
        return results;
    } catch (err) {
        ErrorHelper.handle(err);
        return null;
    }
}

export const exactRemoveWebHookSubscription = async (datasourceId: number, subscriptionId: string) => {
    try {
        const results = (await axiosDelete(`Exact/HookUnsubscribe/${datasourceId}/${subscriptionId}`))?.data;
        return results;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export const deleteUsers = async (request: UserDeleteRequest) => {
    try {
        const results = (await axiosPut(`${process.env.REACT_APP_API_URL}user/delete`, request))?.data;
        return results;
    } catch (err) {
        ErrorHelper.handle(err);
        return null;
    }
}

export const getSubscriptionReport = async (dateRange: DateRange, userId: number) => {
    try {
        const results = (await axiosGet(`${process.env.REACT_APP_API_URL}subscriptions/report/${userId.toString()}?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`, {
            responseType: 'blob'
        }));

        return results?.data;
    } catch (err) {
        ErrorHelper.handle(err);
        return null;
    }
}

export const GetDatasourceAuthUrl = async (requestDto: IDatasourceAuthDto) => {
    try {
        // const authUrl = (await axiosPost(`Auth/GetDatasourceAuthUrl`, requestDto))?.data;
        // return authUrl;
        const authUrl = (await axios.post(
            `${process.env.REACT_APP_API_URL}Auth/GetDatasourceAuthUrl`,
            requestDto,
            {
                headers: {
                    [apiHeaders.key.xDocubirdAuth]: apiHeaders.value.xDocubirdAuth
                }
            }))?.data;
        return authUrl;
    } catch (err) {
        console.error(err);
        return null;
    }
}

export const getAdminConsentUrl = async (tid: string, sct: string) => {
    try {
        const url: string = (await axios.get(
            `${process.env.REACT_APP_API_URL}consent/consenturl/${tid}?sct=${sct}`,
            {
                headers: {
                    [apiHeaders.key.xDocubirdAuth]: apiHeaders.value.xDocubirdAuth
                }
            }))?.data;
        return url;
    } catch (err) {
        ErrorHelper.handle(err);
        return null;
    }
}