import React from 'react';
import { LanguageContext } from '../../utilities/LocalizationModule';
import { Panel, Label, Stack, Dropdown, PrimaryButton, Text, mergeStyles, TooltipHost, IconButton, TooltipDelay, Toggle, PanelType } from "@fluentui/react"
import { labelHeadingStyle, dropdownStyles, tooltipHeadingStyle, tooltipTextStyle } from "../../styles/PanelStyle"
import { DayPickerStrings } from "../../utilities/DayPickerStrings"
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../redux';
import { bulkUpdateUsers } from '../../redux/modules/user';
import { LandingPage, LicenseType } from '../../data-structures/enums'
import { IBulkUpdate, ITenant, ITenantUser } from '../../data-structures/interfaces';

const mapStateToProps = (state: RootState) => {
    return {
        isPartner: state.user.isPartner,
        isGlobalAdmin: state.user.isGlobalAdmin,
        tenants: state.user.tenants,
        users: state.user.users
    };
};

const mapDispatchToProps = {
    bulkUpdateUsers
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface EditBulkPanelProps extends PropsFromRedux {
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    content: { id: number, tenantId: string, tenantName: string, displayName: string }[],
};


const EditBulkPanel: React.FC<EditBulkPanelProps> = ({
    isOpen,
    setIsOpen,
    content,
    bulkUpdateUsers,
    isPartner,
    isGlobalAdmin,
    tenants,
    users
}: EditBulkPanelProps) => {
    const [selectedLicense, setSelectedLicense] = React.useState<LicenseType>(null);
    const [showCalendar, setShowCalendar] = React.useState<boolean>(false);
    const [selectedDate, setSelectedDate] = React.useState<Date>(null);
    const [bulkUpdate, setBulkUpdate] = React.useState<IBulkUpdate>(null);
    // const dateString = new Date(selectedDate)?.toDateString();
    const languageStrings = React.useContext(LanguageContext);
    const onSelectDate = (date: Date): void => {
        setShowCalendar(!showCalendar);
        setSelectedDate(date);

        let newIsActivated = bulkUpdate.isActivated;
        let newLicenseType = bulkUpdate.licenseType;

        if (bulkUpdate.licenseType === LicenseType.Eval && date < new Date()) {
            newIsActivated = false;
        }
        else if (bulkUpdate.licenseType === LicenseType.Eval && date >= new Date()) {
            newIsActivated = true;
        }
        else if (bulkUpdate.licenseType === LicenseType.Expired && date >= new Date()) {
            newIsActivated = true;
            newLicenseType = LicenseType.Eval;
        }

        setBulkUpdate({
            ...bulkUpdate,
            isActivated: newIsActivated,
            licenseType: newLicenseType,
            licenseExpiryDate: date
        })
        setSelectedLicense(newLicenseType);
    };

    const sUniqueTenants = new Set<string>()
    content.forEach(c => sUniqueTenants.add(c.tenantName));
    const uniqueTenants = Array.from(sUniqueTenants);
    React.useEffect(() => {
        if (isOpen) {
            const ids: number[] = content.map(c => c.id);

            const selectedTenants: ITenant[] = tenants.filter(t => content.map(c => c.tenantId).includes(t.tenantId));
            const selectedUsers: ITenantUser[] = users.filter(u => ids.some(uid => uid === u.id));

            let defaultLicenseType: LicenseType = selectedUsers?.length > 0 ? selectedUsers[0].licenseType : null;
            if (defaultLicenseType) {
                if (!selectedUsers.every(u => u.licenseType === defaultLicenseType)) defaultLicenseType = null;
            }

            let defaultLicenseExpiryDate: Date = null;
            if (defaultLicenseType && defaultLicenseType !== LicenseType.Paid) {
                defaultLicenseExpiryDate = selectedUsers?.length > 0 ? selectedUsers[0].licenseExpiryDate : null;
                if (defaultLicenseExpiryDate) {
                    if (!selectedUsers.every(u => u.licenseExpiryDate === defaultLicenseExpiryDate)) defaultLicenseExpiryDate = null;
                }
            }

            setSelectedLicense(defaultLicenseType);
            setSelectedDate(defaultLicenseExpiryDate);

            const defaultSaveOnSend: boolean = isGlobalAdmin ? selectedTenants.some(t => t.saveOnSend) : false;

            // Default true
            let defaultEnableSharePoint: boolean = selectedUsers[0].enableSharePoint;
            if (!selectedUsers.every(u => u.enableSharePoint === defaultEnableSharePoint)) defaultEnableSharePoint = true;

            let defaultEnableOneDrive: boolean = selectedUsers[0].enableOneDrive;
            if (!selectedUsers.every(u => u.enableOneDrive === defaultEnableOneDrive)) defaultEnableOneDrive = true;

            let defaultEnableTeams: boolean = selectedUsers[0].enableTeams;
            if (!selectedUsers.every(u => u.enableTeams === defaultEnableTeams)) defaultEnableTeams = true;

            let defaultIsActivated = defaultLicenseType === LicenseType.Paid ? true : false;

            // Default false
            let defaultAutoSelectAttachments: boolean = selectedUsers[0].autoSelectAttachments;
            if (!selectedUsers.every(u => u.autoSelectAttachments === defaultAutoSelectAttachments)) defaultAutoSelectAttachments = false;

            let defaultAutoRemoveMailItemAttachments: boolean = selectedUsers[0].autoRemoveMailItemAttachments;
            if (!selectedUsers.every(u => u.autoRemoveMailItemAttachments === defaultAutoRemoveMailItemAttachments)) defaultAutoRemoveMailItemAttachments = false;

            let defaultConvertEmailToPdf: boolean = selectedUsers[0].convertEmailToPDF;
            if (!selectedUsers.every(u => u.convertEmailToPDF === defaultConvertEmailToPdf)) defaultConvertEmailToPdf = false;

            let defaultEnableApprovals: boolean = selectedUsers[0].enableApprovals;
            if (!selectedUsers.every(u => u.enableApprovals === defaultEnableApprovals)) defaultEnableApprovals = false;

            let defaultLandingPage: LandingPage = selectedUsers?.length > 0 ? selectedUsers[0].landingPage : null;
            if (defaultLandingPage) {
                if (!selectedUsers.every(u => u.landingPage === defaultLandingPage)) defaultLandingPage = null;
            }

            setBulkUpdate({
                userIds: ids,
                licenseType: defaultLicenseType,
                licenseExpiryDate: defaultLicenseExpiryDate,
                enableSharePoint: defaultEnableSharePoint,
                enableOneDrive: defaultEnableOneDrive,
                enableTeams: defaultEnableTeams,
                saveOnSend: defaultSaveOnSend,
                autoSelectAttachments: defaultAutoSelectAttachments,
                autoRemoveMailItemAttachments: defaultAutoRemoveMailItemAttachments,
                convertEmailToPDF: defaultConvertEmailToPdf,
                enableApprovals: defaultEnableApprovals,
                isActivated: defaultIsActivated,
                landingPage: defaultLandingPage
            });
        }
    }, [isOpen, content]);

    const landingPageOptions = [
        { key: LandingPage.Default, text: languageStrings.Default },
        { key: LandingPage.SharePoint, text: "SharePoint" },
        { key: LandingPage.Teams, text: "Teams" },
        { key: LandingPage.OneDrive, text: "OneDrive" },
        { key: LandingPage.Exchange, text: "Exchange" }
    ]

    const isActivatedEnabled = () => {
        if (!isPartner && !isGlobalAdmin) {
            return false;
        }

        if (bulkUpdate?.licenseType === LicenseType.Eval && new Date(bulkUpdate?.licenseExpiryDate) < new Date()) {
            return false;
        }

        if (bulkUpdate?.licenseType === LicenseType.Expired) {
            return false;
        }

        return true;
    }

    const TooltipContent = () => {
        return <>
            <Label styles={tooltipHeadingStyle} style={{ paddingBottom: 0 }}>{languageStrings.Users}</Label>
            <ul className={mergeStyles({ paddingLeft: 25 })}>
                <Stack>
                    {[...content].sort().map((u, i) =>
                        <li key={i}><Label styles={tooltipTextStyle}>
                            {u.displayName}
                        </Label></li>)}
                </Stack>
            </ul>
            <Label styles={tooltipHeadingStyle}>{languageStrings.Tenants}</Label>
            <ul className={mergeStyles({ paddingLeft: 25 })}>
                <Stack>
                    {[...uniqueTenants].filter(t => t != '').sort().map((t, i) =>
                        <li key={i}><Label styles={tooltipTextStyle}>
                            {t}
                        </Label></li>)}
                </Stack>
            </ul>
        </>
    }

    return (
        <Panel
            headerText="Edit users"
            isOpen={isOpen}
            isLightDismiss
            onDismiss={() => setIsOpen(false)}
            closeButtonAriaLabel="Close"
            type={PanelType.custom}
            customWidth={"500px"}
        >
            <Stack tokens={{ childrenGap: 15 }}>
                <Stack horizontal verticalAlign='center'>
                    <Text>{content.length} user{content.length == 1 ? '' : 's'} selected {uniqueTenants.length > 1 ? `across ${uniqueTenants.length} tenants` : ''}</Text>
                    <TooltipHost
                        content={<TooltipContent />}
                        calloutProps={{ gapSpace: 0, calloutWidth: 200 }}
                        styles={{
                            root: {
                                display: 'inline-block',
                            }
                        }}
                        delay={TooltipDelay.zero}
                    >
                        <IconButton iconProps={{
                            iconName: 'Info'
                        }} />
                    </TooltipHost>
                </Stack>
                {(isPartner || isGlobalAdmin) && <Stack.Item>
                    <Toggle
                        label={<Label styles={labelHeadingStyle}>{languageStrings.Active}</Label>}
                        checked={bulkUpdate?.isActivated ? true : false}
                        onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => {
                            setBulkUpdate({ ...bulkUpdate, isActivated: checked ? checked : false })
                        }}
                        onText={languageStrings.Yes}
                        offText={languageStrings.No}
                        disabled={!isActivatedEnabled()}
                    />
                </Stack.Item>}
                <Stack.Item >
                    <Label styles={labelHeadingStyle}>{languageStrings.GeneralSettings}</Label>
                    <Toggle
                        label={languageStrings.Show + " SharePoint"}
                        checked={bulkUpdate?.enableSharePoint ? true : false}
                        onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setBulkUpdate({ ...bulkUpdate, enableSharePoint: checked ?? false })}
                        onText={languageStrings.Yes}
                        offText={languageStrings.No}
                    />
                    <Toggle
                        label={languageStrings.Show + " Teams"}
                        checked={bulkUpdate?.enableTeams ? true : false}
                        onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setBulkUpdate({ ...bulkUpdate, enableTeams: checked ?? false })}
                        onText={languageStrings.Yes}
                        offText={languageStrings.No}
                    />
                    <Toggle
                        label={languageStrings.Show + " OneDrive"}
                        checked={bulkUpdate?.enableOneDrive ? true : false}
                        onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setBulkUpdate({ ...bulkUpdate, enableOneDrive: checked ?? false })}
                        onText={languageStrings.Yes}
                        offText={languageStrings.No}
                    />
                    <Toggle
                        label={languageStrings.EnableApprovals}
                        checked={bulkUpdate?.enableApprovals ? true : false}
                        onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setBulkUpdate({ ...bulkUpdate, enableApprovals: checked ?? false })}
                        onText={languageStrings.Yes}
                        offText={languageStrings.No}
                    />
                    <Stack.Item>
                        <Label styles={labelHeadingStyle}>{languageStrings.LandingPage}</Label>
                        <Dropdown
                            style={{ marginTop: 7 }}
                            styles={dropdownStyles}
                            selectedKey={bulkUpdate?.landingPage?.valueOf()}
                            onChange={(_e, o) => setBulkUpdate({ ...bulkUpdate, landingPage: Number(o.key) })}
                            options={landingPageOptions}
                        />
                    </Stack.Item>
                </Stack.Item>
                <Stack.Item>
                    <Label styles={labelHeadingStyle}>
                        {languageStrings.OutlookSettings}
                    </Label>
                    <Toggle
                        disabled={true}
                        label={languageStrings.SaveOnSend}
                        checked={bulkUpdate?.saveOnSend ? true : false}
                        onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setBulkUpdate({ ...bulkUpdate, saveOnSend: checked ?? false })}
                        onText={languageStrings.Yes}
                        offText={languageStrings.No}
                    />
                    <Toggle
                        label={languageStrings.AutoSelectAttachments}
                        checked={bulkUpdate?.autoSelectAttachments ? true : false}
                        onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setBulkUpdate({ ...bulkUpdate, autoSelectAttachments: checked ?? false })}
                        onText={languageStrings.Yes}
                        offText={languageStrings.No}
                    />
                    <Toggle
                        label={languageStrings.AutoRemoveMailItemAttachments}
                        checked={bulkUpdate?.autoRemoveMailItemAttachments ? true : false}
                        onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setBulkUpdate({ ...bulkUpdate, autoRemoveMailItemAttachments: checked ?? false })}
                        onText={languageStrings.Yes}
                        offText={languageStrings.No}
                    />
                    <Toggle
                        label={languageStrings.ConvertEmailToPdf}
                        checked={bulkUpdate?.convertEmailToPDF ? true : false}
                        onChange={(_event: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean) => setBulkUpdate({ ...bulkUpdate, convertEmailToPDF: checked ?? false })}
                        onText={languageStrings.Yes}
                        offText={languageStrings.No}
                    />
                </Stack.Item>
                <Stack horizontal horizontalAlign="end">
                    <PrimaryButton style={{ marginTop: 5 }}
                        disabled={false}
                        onClick={async () => {
                            bulkUpdateUsers(bulkUpdate)
                            setIsOpen(false); //close panel
                        }} iconProps={{ iconName: 'save' }}>
                        {languageStrings.Save}
                    </PrimaryButton>
                </Stack>

            </Stack>

        </Panel>
    )
}

export default connector(EditBulkPanel);